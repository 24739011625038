// Navbar.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth, signOut } from 'firebase/auth';
import './Navbar.css';

const Navbar = () => {

  const [name, setName] = useState('User not recognized');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const db = getDatabase();
    const auth = getAuth();

    if (auth.currentUser) {
      const displayNameRef = ref(db, `users/${auth.currentUser.uid}/displayName`);

      get(displayNameRef).then((snapshot) => {
        if (snapshot.exists) {
          setName(snapshot.val());
        }
      }).catch((err) => {
        console.log(err);
      });

    }
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Error logging out:', error);
    });
  };


  return (
    <div className="navbar">
      <div className="nav-logo-container">
        <img src="peerlogoround.png" alt="Logo" className="peerlogo"></img>
      </div>
      <div className="nav-items">
        <div className="nav-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
            <path d="M22.4839 9.97852C22.4839 10.6815 21.8981 11.2322 21.2341 11.2322H19.9844L20.0117 17.4888C20.0117 17.5942 20.0039 17.6997 19.9922 17.8051V18.4378C19.9922 19.3009 19.2931 20 18.43 20H17.8051C17.7622 20 17.7192 20 17.6762 19.9961C17.6216 20 17.5669 20 17.5122 20L16.2429 19.9961H15.3056C14.4425 19.9961 13.7434 19.297 13.7434 18.4339V17.4966V14.9971C13.7434 14.3058 13.1849 13.7473 12.4937 13.7473H9.99414C9.30287 13.7473 8.74439 14.3058 8.74439 14.9971V17.4966V18.4339C8.74439 19.297 8.0453 19.9961 7.18219 19.9961H6.24487H4.99902C4.94044 19.9961 4.88186 19.9922 4.82328 19.9883C4.77641 19.9922 4.72955 19.9961 4.68268 19.9961H4.0578C3.19469 19.9961 2.49561 19.297 2.49561 18.4339V14.0598C2.49561 14.0246 2.49561 13.9856 2.49951 13.9504V11.2283H1.24976C0.546768 11.2283 0 10.6815 0 9.97461C0 9.62312 0.117165 9.31068 0.390549 9.0373L10.4003 0.312439C10.6737 0.0390549 10.9861 0 11.2595 0C11.5329 0 11.8453 0.0781097 12.0797 0.273384L22.0543 9.0412C22.3667 9.31459 22.5229 9.62703 22.4839 9.97852Z" fill="#0080A9" />
          </svg>
          <p className="nav-title">Home</p>
        </div>
        <div className="nav-item active">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path d="M2.7399 0C1.49884 0 0.489807 1.00903 0.489807 2.2501V12.3755C0.489807 13.6166 1.49884 14.6256 2.7399 14.6256H6.11505V17.4383C6.11505 17.6527 6.23459 17.8461 6.42444 17.941C6.61429 18.0359 6.84282 18.0148 7.01509 17.8883L11.3641 14.6256H16.2405C17.4816 14.6256 18.4906 13.6166 18.4906 12.3755V2.2501C18.4906 1.00903 17.4816 0 16.2405 0H2.7399Z" fill="#0080A9" />
          </svg>
          <p className="nav-title">Messages</p>
        </div>
        <div className="nav-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path d="M6.91914 1.92857C6.91914 0.863839 7.78298 0 8.84771 0H10.1334C11.1982 0 12.062 0.863839 12.062 1.92857V16.0714C12.062 17.1362 11.1982 18 10.1334 18H8.84771C7.78298 18 6.91914 17.1362 6.91914 16.0714V1.92857ZM0.49057 9.64286C0.49057 8.57812 1.35441 7.71429 2.41914 7.71429H3.70486C4.76959 7.71429 5.63343 8.57812 5.63343 9.64286V16.0714C5.63343 17.1362 4.76959 18 3.70486 18H2.41914C1.35441 18 0.49057 17.1362 0.49057 16.0714V9.64286ZM15.2763 2.57143H16.562C17.6267 2.57143 18.4906 3.43527 18.4906 4.5V16.0714C18.4906 17.1362 17.6267 18 16.562 18H15.2763C14.2116 18 13.3477 17.1362 13.3477 16.0714V4.5C13.3477 3.43527 14.2116 2.57143 15.2763 2.57143Z" fill="#0080A9" />
          </svg>
          <p className="nav-title">Analytics</p>
        </div>
      </div>
      <div className="nav-profile">
        <div className="nav-user-info">
          <p className="nav-name">Hello {name}!</p>
          <div className="logout-container">
            <button onClick={handleLogout} className="nav-logout">Logout</button>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M8.85703 2.48203L11.7352 5.36016C11.9039 5.52891 12 5.76094 12 6C12 6.23906 11.9039 6.47109 11.7352 6.63984L8.85703 9.51797C8.70703 9.66797 8.50547 9.75 8.29453 9.75C7.85625 9.75 7.5 9.39375 7.5 8.95547V7.5H4.5C4.08516 7.5 3.75 7.16484 3.75 6.75V5.25C3.75 4.83516 4.08516 4.5 4.5 4.5H7.5V3.04453C7.5 2.60625 7.85625 2.25 8.29453 2.25C8.50547 2.25 8.70703 2.33438 8.85703 2.48203ZM3.75 2.25H2.25C1.83516 2.25 1.5 2.58516 1.5 3V9C1.5 9.41484 1.83516 9.75 2.25 9.75H3.75C4.16484 9.75 4.5 10.0852 4.5 10.5C4.5 10.9148 4.16484 11.25 3.75 11.25H2.25C1.00781 11.25 0 10.2422 0 9V3C0 1.75781 1.00781 0.75 2.25 0.75H3.75C4.16484 0.75 4.5 1.08516 4.5 1.5C4.5 1.91484 4.16484 2.25 3.75 2.25Z" fill="#0080A9" />
            </svg>
          </div>

        </div>
        <div className="profile-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28" fill="none">
            <path d="M12.5 14.1746C14.3186 14.1746 16.0628 13.4429 17.3487 12.1406C18.6347 10.8383 19.3571 9.07193 19.3571 7.23015C19.3571 5.38837 18.6347 3.62202 17.3487 2.31969C16.0628 1.01735 14.3186 0.285706 12.5 0.285706C10.6814 0.285706 8.93723 1.01735 7.65127 2.31969C6.3653 3.62202 5.64286 5.38837 5.64286 7.23015C5.64286 9.07193 6.3653 10.8383 7.65127 12.1406C8.93723 13.4429 10.6814 14.1746 12.5 14.1746ZM10.0518 16.7788C4.775 16.7788 0.5 20.759 0.5 26.1029C0.5 26.9927 1.2125 27.7143 2.09107 27.7143H22.9089C23.7875 27.7143 24.5 26.9927 24.5 26.1029C24.5 20.759 20.225 16.7788 14.9482 16.7788H10.0518Z" fill="white" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
