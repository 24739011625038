import { useState, useEffect } from 'react';
import { useAppState } from '../AppStateContext';
import { database } from '../firebase';
import { ref, onValue } from 'firebase/database';

import './Sidebar.css';

function Sidebar() {
  const [chats, setChats] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const { setSelectedConversation } = useAppState();

  const handleClick = (conversation) => {
    setSelectedConversation(conversation); // global state
    setActiveId(conversation.id); // local state
  };

  useEffect(() => {
    const chatsRef = ref(database, 'chats');
    
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const chatsList = [];
      snapshot.forEach((child) => {
        chatsList.push({ id: child.key, ...child.val() });
      });
      setChats(chatsList);
    });
    
    return () => unsubscribe();
  }, []);

  return (
    <div className='sidebar'>
      <h4 className='sidebar-title'>Messages</h4>
      <hr className='sidebar-divider'></hr>
      <div className='sidebar-links'>
        {chats.map(chat => (
          <button
            key={chat.id}
            className={`sidebar-link ${activeId === chat.id ? 'active' : ''}`}
            onClick={() => handleClick(chat)}>
            {chat.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
