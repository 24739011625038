import { useState, useEffect, useRef } from 'react';
import { database } from '../firebase';
import { ref, push, onValue } from 'firebase/database';
import { useAppState } from '../AppStateContext';
import './ChatWindow.css';

function ChatWindow() {
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { selectedConversation } = useAppState();
  const chatPartner = selectedConversation ? selectedConversation.name : 'Select a conversation';

  useEffect(() => {
    if (!selectedConversation) {
      return;
    }

    const messagesRef = ref(database, `messages/${selectedConversation.id}`);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const messagesList = [];
      snapshot.forEach((child) => {
        messagesList.push({ id: child.key, ...child.val() });
      });
      setMessages(messagesList);
    });

    return () => unsubscribe();
  }, [selectedConversation]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = () => {
    if (selectedConversation && newMessage.trim()) {
      const newMessageRef = ref(database, `messages/${selectedConversation.id}`);

      push(newMessageRef, {
        name: 'Nurse / Caregiver',
        message: newMessage,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      });

      setNewMessage('');
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className='chat-window'>
      <div className='chat-header'>
        <h3 className='chat-partner-name'>{chatPartner}</h3>
      </div>
      <div className='chat-messages'>
        {messages.map((msg) => (
          <div key={msg.id} className={`chat-message ${msg.name === 'Nurse / Caregiver' ? 'right' : ''}`}>
            <div className='message-content'>{msg.message}</div>
            <div className='message-meta'>
              <span className='message-author'>{msg.name}</span>
              <span className='message-time'>{msg.time}</span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className='chat-input'>
        <textarea
          placeholder='Type a message...'
          value={newMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        ></textarea>
        <button onClick={sendMessage} className='chat-send-button'>Send</button>
      </div>
    </div>
  );
}

export default ChatWindow;
