// ChatApp.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import ChatWindow from '../components/ChatWindow';
import PAPA from '../components/PAPA';
import Navbar from '../components/Navbar';
import { useAppState } from '../AppStateContext';
import './ChatApp.css';

function ChatApp() {
  const { isPapaVisible } = useAppState();

  return (
    <div className='home'>
      <Navbar />
      <div className='main-content'>
        <div className='left-sidebar'>
          <Sidebar />
          {isPapaVisible && (
            <div className='PAPA'>
              <PAPA />
            </div>
          )}
        </div>
        <div className='content'>
          <ChatWindow />
        </div>
      </div>
    </div>
  );
}

export default ChatApp;
